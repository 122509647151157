import { createGlobalStyle } from "styled-components";

export const Styles = createGlobalStyle`

    @font-face {
        font-family: "Inter Regular";
        src: url("/fonts/Inter-Regular.ttf") format("truetype");
        font-style: normal;
    }

    @font-face {
        font-family: "Inter Bold";
        src: url("/fonts/Inter-Bold.ttf") format("truetype");
        font-style: normal;
    }


    body,
    html,
    a {
        font-family: 'Inter Regular', sans-serif;
    }


    body {
        margin:0;
        padding:0;
        border: 0;
        outline: 0;
        background: #F4F8FB;
        overflow-x: hidden;
    }

    a:hover {
        color: #486ADC;
    }

    input,
    textarea {
        border-radius: 4px;
        border: 0;
        background: rgb(241, 242, 243);
        transition: all 0.3s ease-in-out;  
        outline: none;
        width: 100%;  
        padding: 1rem 1.25rem;

        :focus-within {
            background: none;
            box-shadow: #486ADC 0px 0px 0px 1px;
        }
    }

    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
        font-family: 'Inter Bold', serif;
        color: #486ADC;
    }

    p {
        color: #486ADC;
        font-size: 21px;        
        line-height: 1.41;
    }


    h1 {
        font-weight: 600;
        font-size: 56px;
        line-height: 1.2;
        @media only screen and (max-width: 890px) {
            font-size: 47px;
          }
        
          @media only screen and (max-width: 414px) {
            font-size: 32px;
          }
    }
    h2{
        font-size: 32px;
        line-height: 1.2;
        @media only screen and (max-width: 890px) {
          font-size: 28px;
        }
      
        @media only screen and (max-width: 414px) {
          font-size: 24px;
        }
    }
    h3{
        font-size: 28px;
        line-height: 1.2;
        @media only screen and (max-width: 890px) {
          font-size: 24px;
        }
      
        @media only screen and (max-width: 414px) {
          font-size: 20px;
        }
    }
    h4{
        font-size: 24px;   
        line-height: 1.2;

        @media only screen and (max-width: 890px) {
          font-size: 20px;
        }
      
        @media only screen and (max-width: 414px) {
          font-size: 16px;
        }
    }
    h5{
        font-size: 20px;

        @media only screen and (max-width: 890px) {
          font-size: 16px;
        }
      
        @media only screen and (max-width: 414px) {
          font-size: 14px;
        }
    }
    h6{
        font-size: 16px;
   

        @media only screen and (max-width: 890px) {
          font-size: 32px;
        }
      
        @media only screen and (max-width: 414px) {
          font-size: 26px;
        }
    }

    a {
        text-decoration: none;
        outline: none;
        color: #486ADC;

        :hover {
            color: #486ADC;
        }
    }
    
    *:focus {
        outline: none;
    }

    .about-block-image svg {
        text-align: center;
    }

    .ant-drawer-body {
        display: flex;
        flex-direction: column;
        text-align: left;
        padding-top: 1.5rem;
    }

    .ant-drawer-content-wrapper {
        width: 300px !important;
    }
    
`;
