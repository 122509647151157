import { Row, Col } from "antd";
import { withTranslation } from "react-i18next";
import { SvgIcon } from "../../common/SvgIcon";
import Container from "../../common/Container";
import Contact from "../ContactBlock";

import ContactContent from "../../content/ContactContent.json";

import i18n from "i18next";
import {
  FooterSection,
  Title,
  NavLink,
  Extra,
  LogoContainer,
  Para,
  Large,
  Chat,
  Empty,
  FooterContainer,
} from "./styles";

interface SocialLinkProps {
  href: string;
  src: string;
}

const Footer = ({ t }: any) => {
  const handleChange = (language: string) => {
    i18n.changeLanguage(language);
  };

  const SocialLink = ({ href, src }: SocialLinkProps) => {
    return (
      <a
        href={href}
        target="_blank"
        rel="noopener noreferrer"
        key={src}
        aria-label={src}
      >
        <SvgIcon src={src} width="25px" height="25px" />
      </a>
    );
  };

  return (
    <>
      <FooterSection>
        <Contact
          title={ContactContent.title}
          content={ContactContent.text}
          contactInstagram={ContactContent.contactInstagram}
          contactEmail={ContactContent.contactEmail}
          linkInstagram={ContactContent.linkInstagram}
          linkEmail={ContactContent.linkEmail}
          iconInstagram={ContactContent.iconInstagram}
          iconEmail={ContactContent.iconEmail}
          id="contact"
        />
        <Empty />
        <Container>
          <Row justify="space-between">
            <Col lg={8} md={10} sm={12} xs={12}>
              <Title>{t("Contato")}</Title>
              <Para>
                {t(`Você tem alguma pergunta? Sinta-se à vontade para entrar em contato.`)}
              </Para>
              <a href="mailto:contato@floats.com.br">
                <Chat>{t(`contato@floats.com.br`)}</Chat>
              </a>
            </Col>
            <Col lg={16} md={8} sm={12} xs={12}>
              <SvgIcon src="logo-centelhago.svg" width="30%" height="30%" />
              <SvgIcon src="logo-fapeg.svg" width="30%" height="30%" />
              <SvgIcon src="logo-ufg.svg" width="40%" height="40%" />
            </Col>
          </Row>
          <Row justify="space-between">
            <Col lg={10} md={10} sm={12} xs={12}>
              <Empty />
              <Title>{t("Onde estamos")}</Title>
              <Para>Goiânia - Goiás</Para>
              <Para>Brasil</Para>
            </Col>
          </Row>
        </Container>
      </FooterSection>
      <Extra>
        <Container border={true}>
          <Row
            justify="space-between"
            align="middle"
            style={{ paddingTop: "3rem" }}
          >
            <NavLink to="/">
              <LogoContainer>
                <SvgIcon
                  src="logo.svg"
                  aria-label="homepage"
                  width="101px"
                  height="64px"
                />
              </LogoContainer>
            </NavLink>
            <FooterContainer>
              <SocialLink
                href="https://www.linkedin.com/company/floats/"
                src="linkedin.svg"
              />
              <SocialLink
                href="https://www.instagram.com/floats_oficial"
                src="icons8-instagram.svg"
              />
            </FooterContainer>
          </Row>
        </Container>
      </Extra>
    </>
  );
};

export default withTranslation()(Footer);
