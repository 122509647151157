import styled from "styled-components";

export const ContactBackground = styled("div")`
  padding: 2rem 2rem;
  position: relative;
  width: 100%; 
  margin-right: auto;
  margin-left: auto;
  background: #486ADC;

  @media only screen and (max-width: 1024px) {
    padding: 1rem 30px;
  }

  @media only screen and (max-width: 768px) {
    padding: 1rem 18px;
  }

  @media only screen and (max-width: 414px) {
    padding: 1rem 18px;
  }
`;

export const ContactContainer = styled("div")`
  position: relative;
  max-width: 1200px;
  margin-right: auto;
  margin-left: auto;
`

export const TextWrapper = styled("div")`
  padding: 0 3rem;
`;

export const IconWrapper = styled("div")`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const Title = styled("h3")`
  font-family: 'Inter Regular';
  font-size: 2.0064rem;
  color: #F4F8FB;
`;


export const Content = styled("p")`
  font-family: 'Inter Regular';
  font-size: 1.0032rem;
  text-align: justify;
  color: #F4F8FB;
`;

export const Link = styled("p")`
  font-family: 'Inter Regular';
  font-weight: 700;
  font-size: 1.1704rem;
  color: #F4F8FB;
  margin: 0.5rem;
`;

