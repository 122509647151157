const routes = [
  {
    path: ["/", "/home"],
    exact: true,
    component: "Home",
  },
  {
    path: ["/aplicacoes"],
    exact: true,
    component: "Aplicacoes",
  },
  {
    path: ["/solucoes"],
    exact: true,
    component: "Solucoes",
  },
  {
    path: ["/cases_de_sucesso"],
    exact: true,
    component: "SucessCases"
  },
  {
    path: ["/noticias"],
    exact: true,
    component: "Noticias"
  },
];

export default routes;
