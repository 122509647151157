import { Row, Col } from "antd";
import { withTranslation } from "react-i18next";
import { SvgIcon} from "../../common/SvgIcon";
import { ContactBackground, ContactContainer, TextWrapper, IconWrapper, Title, Content, Link } from "./styles";

interface SocialLinkProps {
  href: string;
  src: string;
  text: string;
}

const SocialLink = ({ href, src, text }: SocialLinkProps) => {
  return (
    <a
      href={href}
      key={src}
      aria-label={src}
    >
      <IconWrapper>
        <SvgIcon src={src} width="54px" height="32px" />
        <Link>{text}</Link>
      </IconWrapper>
    </a>
  );
};

interface ContactBlockProps {
  title: string;
  content: string;
  contactInstagram: string;
  contactEmail: string;
  linkInstagram: string;
  linkEmail: string;
  iconInstagram: string;
  iconEmail: string;
  id: string;
  t: any;
}

const ContactBlock = ({
  title,
  content,
  contactInstagram,
  contactEmail,
  linkInstagram,
  linkEmail,
  iconInstagram,
  iconEmail,
  id,
  t,
}: ContactBlockProps) => {
  return (
    <ContactBackground>
      <ContactContainer id={id}>
        <Row align="middle">
          <Col lg={12} md={24} sm={24} xs={24}>
            <TextWrapper> 
              <Title>{t(title)}</Title>
              <Content>{t(content)}</Content>
            </TextWrapper>
          </Col>

          <Col lg={1} md={1}>
          </Col>

          <Col lg={4} md={24} sm={24} xs={24}>

            <SocialLink
              href={`https:/${t(linkInstagram)}`}
              src={t(iconInstagram)}
              text={t(contactInstagram)}
            />
          </Col>

          <Col lg={1} md={1}>
          </Col>

          <Col lg={4} md={24} sm={24} xs={24}>
            <SocialLink
              href={`mailto:${t(linkEmail)}`}
              src={t(iconEmail)}
              text={t(contactEmail)}
            />
          </Col>

        </Row>
      </ContactContainer>
    </ContactBackground>
  );
};

export default withTranslation()(ContactBlock);
